import { useAuthStore } from '@/stores/useAuthStore';

export default defineNuxtRouteMiddleware((_to, from) => {
    const auth = useAuthStore();

    if (auth.user?.actionRole.includes('SUPERADMIN')) return true;
    if (!auth.user || !auth.user.isManager) {
        return navigateTo(from.fullPath, { replace: true });
    }
});
